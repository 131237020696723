<div class="movie">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>    
    <div class="maincontainer">
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
            <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>        
        <div class="middle">
            <div class="content">
               <div class="inside">
                <div class="first">
                    <!-- <div class="left">&#10094;</div> -->
                <div class="info">
                   
                </div>
                <div class="empty">
                    <!-- <h1>The Dark night</h1>
                    <p>
                        What is Lorem Ipsum Lorem Ipsum is simply dummy text of the printing and typesetting industry Lorem
                        Ipsum has
                        been the industry's standard dummy text ever since the 1500s when an unknownand
                    </p>
                    <strong>
                        What is Lorem Ipsum Lorem Ipsum is simply dummy text of th
                    </strong> -->
                </div>
               
                <!-- <div class="right">&#10095;</div> -->
                </div>
                <div class="second">
                    <!-- <div class="btncontainer">
                        <div class="left">
                            
                            <div class="buttons">
                                <div class="play">
                                    <button>PLAY NOW</button>
                                </div>
                                <div class="watch">
                                    <button>Play Trailer</button>
                                </div>
                                <div class="list">
                                    <i class="fas fa-plus active" aria-hidden="true"></i>
                                </div>
                                <div class="list">
                                    <i class="fa fa-share-alt" aria-hidden="true"></i>
                                </div>
                            </div>
                        </div>
                        <div class="right">
                                <div style="width:25%; text-align:right">
                                    Seasons
                                </div> 
                                <div class="active">1</div>&nbsp; <div>2</div>&nbsp; <div>3</div>&nbsp; <div>4</div>
                        </div>
                    </div> -->
                </div>
               </div>
            </div>
            
        </div>
    </div>
    <app-back></app-back>
    <!-- <div class="back">
        <div>
          <a [routerLink]="['/vod']">
            <i class="fa fa-arrow-left" aria-hidden="true"></i>
            </a>

        </div>  
        </div> -->
    <div class="cont">
        <div class="row">
          <!-- <h4>Trending Now</h4> -->
          <div class="carousel" *ngFor="let data of wholeData">
              <ng-container *ngIf="(data.name != 'DOCUMENTARIES') && (data.name != 'LIFESTYLE AND TRAVEL')&& (data.name != 'KIDS')">
                <h3 style="color:#fff">{{data.name}}
                </h3>
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"  style="display:flex" *ngFor="let image of data.playlists; let i=index" class="slide">
                   
                    <ng-container *ngIf="image.content.length >= 7 && i == 0 ">
                  <div ngxSlickItem *ngFor="let playlist of image.content" >
                    <div class="playlist"  (click)="vdoModals(playlist)">
                        <img src="{{ playlist.imageCropped}}" width="100%">
                    </div>
                  </div>
                </ng-container>
                </ngx-slick-carousel>
              </ng-container>
           
        </div>
        </div>
    </div>
</div>