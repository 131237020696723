<div id="modal-content-wrapper">
    <div class="modalcontainer">
        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
            <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <app-alert [Message]="alertMessage" *ngIf="alertShow" (click)="alertClose(alertMessage)"></app-alert>
        <div class="sinlog">
            <div class="mcontainer">
                <div class="left">
                    <div class="login">
                        <h3>Reset Password</h3>
                        <form #changpassword="ngForm" (ngSubmit)="changePassword(changpassword)">
                            <div class="input">
                                <input type="password" name="newpassword" ngModel required placeholder="New password">
                            </div>
                            <div class="input">
                                <input type="password" name="confirmpassword" ngModel required placeholder="Confirm password" (focusout)="passwordCheck(changpassword.value.newpassword, changpassword.value.confirmpassword)">
                            </div>
                            <div class="cont">
                                <button class="btn1" button="submit">SAVE</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">
            <app-foot ></app-foot>
        </div>
    </div>
</div>
