<div id="modal-content-wrapper">  
    <div class="modalcontainer">
        <app-alert [Message]="alertMessage" *ngIf="alertShow" (click)="alertClose(alertMessage)"></app-alert>
      <app-close (click)="closeModal()"></app-close>
        <div class="sinlog" >
            <div class="mcontainer" [ngStyle]="{ 'display': forgetPassword }">
                <div class="left">
                    <div class="login">
                        <h3>Change Password</h3>
                        <form #changpassword="ngForm" (ngSubmit)="changePassword(changpassword)">
                            <div class="input">
                                <input type="password" name="currentpassword" ngModel required placeholder="Current Password" #psswd="ngModel">
                            </div>
                            <div class="foot">
                                <div class="forgot">
                                    <p (click)="showForgotPassword()">Forgot Password?</p>
                                </div>
                            </div>
                            <div class="input">
                                <input type="password" name="newpassword" ngModel required placeholder="New password (4-60 Characters)">
                            </div>
                            <div class="input">
                                <input type="password" name="confirmpassword" ngModel required placeholder="Confirm New password" (focusout)="passwordCheck(changpassword.value.newpassword, changpassword.value.confirmpassword)">
                            </div>
                            <div class="check">
                                <label class="main"> Require all devices to sign in again with password.
                                    <input type="checkbox" name="signin" value="" ngModel required>
                                    <span class="geekmark"></span>
                                </label>    
                            </div>
                            <div class="cont">
                                <button class="btn1" button="submit">SAVE</button>
                                <button class="btn2" button="type" (click)="closeModal()">CANCEL</button>
                            </div>
                        </form>
                    </div>
                </div>
             </div>
             <div [ngStyle]="{ 'display': forgotPasswordShow }">
                <app-forgotpassword></app-forgotpassword>
             </div>
          <div class="footer">
            <app-foot (click)="closeModal()"></app-foot>
          </div>
        </div>
    </div>
</div>