<div class="selectkid">
    <ngx-spinner
          bdColor="rgba(51,51,51,0.8)"
          size="medium"
          color="#fff"
          type="ball-scale-multiple"
        >
          <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
      <div>
        <ng-container *ngFor="let data of bannerImage">
          <!-- <div class="maincontainer" *ngIf="data.id == subCatId" class="maincontainer" style="background: url('{{data.imageUrl}}');background-position: center; 
          background-repeat: no-repeat; 
          background-size: cover;
          position: relative;
          background-color: #000;">
              <div class="middle">
                  <div class="content">
                      <div class="left"></div>
                      <div class="empty">
  
                      </div>
                      <div class="info">
                      </div>
                      <div class="right"></div>
                  </div>
              </div>
          </div> -->
          <div class="mobilebanner" *ngIf="data.id == subCatId">
           <!-- <img src="../../../assets/image/detail.jpg"> -->
           <img src="{{data.imageUrl}}" />
          </div>
        </ng-container>
      </div>
      <app-back></app-back>
      <div class="cont">
        <div class="row" *ngFor="let category of videoData">
        <ng-container *ngIf="category.source.length >= 7">
          <h4>{{category.sub_category_name | uppercase}}</h4>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="trending">
              <div ngxSlickItem *ngFor="let image of category.source" class="slide">
                  <div class="playlist" (click)="goToPlayVideoPage(image, category.sub_category_name)">
                      <!-- <img src="{{ image.profileImage}}" width="100%"> -->
                      <img defaultImage="../../../assets/Images/vdodummy.jpeg"  width="100%" [lazyLoad]="image.profileImage" />
                  </div>
                  <p class="textcenter">{{image.content_name}}</p>
              </div>
          </ngx-slick-carousel>
        </ng-container>
        <ng-container *ngIf="category.source.length < 7">
          <h4 style="color: #fff">{{category.sub_category_name | uppercase}}</h4>
          <div class="parent">
              <div class="child" *ngFor="let image of category.source">
                  <div class="playlist" (click)="goToPlayVideoPage(image, category.sub_category_name)">
                    <img defaultImage="../../../assets/Images/vdodummy.jpeg"  width="100%" [lazyLoad]="image.profileImage" />
                      <h3 style="color:#fff;text-align: center;">{{image.content_name}}
                      </h3>
                  </div>
              </div>
          </div>
        </ng-container>
        </div>
    </div>
  </div>