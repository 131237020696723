<div class="sinlog">
    <div class="mcontainer">
        <div class="left">
            <div class="login">
                <app-alert [Message]="alertMessage" *ngIf="alertShow" (click)="alertClose(alertMessage)"></app-alert>
                <h3 class="header3">Forget Password</h3>
                <p>Enter the email or mobile number used when registering for Avvatta and we will send you instructions
                    to reset your Password</p>
                
                <div class="head" >
                    <div class="tac" [ngClass]="{'active': clicked == 'Phone'}" (click)="show('Phone')"
                    >
                    <h3>Phone </h3>
                </div>
                <div class="faq" [ngClass]="{'active': clicked == 'email'}" (click)="show('email')"
                   >
                    <h3> Email</h3>
                </div>
                </div>
                <div class="body">                
                    <div class="taccont" *ngIf="click=='Phone'"  >
                        <form #fpin="ngForm" (ngSubmit)="forgotPassword(fpin)">
                         <h3>Enter Registered Mobile Number</h3>
                            <!-- <div class="input-box">
                                <div class="unit">{{this.countryCode}}</div>
                                <input type="number" name="mobile" autocomplete="off"  placeholder="Enter Mobile number"  value={Number(this.state.myNumber).toString()}
                                 ngModel required  #mobile="ngModel"  (keyup)="checkMail(fpin.value.mobile)" pattern="[1-9]\d*"
                                 oninput="this.value = !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"/>   
                              </div>                   -->

                              <div class="input-group">
                                <span class="input-group-addon ">
                                    {{this.countryCode}}
                                </span>
                                <input type="tel" name="mobile" autocomplete="off"  placeholder="Enter Mobile number" ngModel required
                                #phone="ngModel" oninput="this.value = 
                                !!this.value && Math.abs(this.value) >= 0 ? Math.abs(this.value) : null"  
                                (keyup)="checkMail(s.value.phone)" />
                              </div>
                              <br>
                            <div class="cont">
                                <button type="submit">CONTINUE</button>
                            </div>
                        </form>

                    </div>
                    <div class="taccont" *ngIf="click=='email'">
                        <form #fpin="ngForm" (ngSubmit)="forgotPassword(fpin)">
                            <h3 style="font-weight:bold;font-size:15px">Enter Registered Email Address</h3>
                            <div class="input">
                                <input type="text" name="email" autocomplete="off" ngModel required #email="ngModel" placeholder="Enter Email" (keyup)="checkMail(fpin.value.email)">
                            </div>
                            <div class="cont">
                                <button type="submit">CONTINUE</button>
                            </div>
                        </form>

                    </div>
                </div>
              
            </div>
        </div>
       
    </div>
</div>