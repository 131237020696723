<!-- <app-video></app-video> -->
<div class="emovie">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner> 
    <div class="mobilebanner">
        <img src="{{banner}}" />
    </div>
    <div class="modules web">
        <div class="cont">
          <div class="row">
            <div class="left">
              <div class="group">                
                <div class="btn" routerLink="/vod/eros/vmusic">
                  <div class="style">
                    <div class="inside">
                      <div class="text" > MUSIC VIDEOS</div>
                    </div>
                  </div>
                </div>
                <div class="btn" (click)="goToNextTab()">
                  <div class="style">
                    <div class="inside">
                      <div class="text" > ALBUMS</div>
                      <!-- <div class="text" routerLink="/vod/eros/amusic"> ALBUMS</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <app-back></app-back>
    <div class="cont">

      <div class="row">
          <div class="carousel" *ngFor="let item of wholeData">
              <ng-container *ngFor="let item1 of item; let i=index"> 
                  <ng-container *ngIf="item1.sources.length >= 7">

                  <h4 style="color: #fff">{{item1.categories}}</h4>
                  <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" style="display:flex" class="slide">
                      <ng-container>
                          <div ngxSlickItem *ngFor="let item2 of item1.sources">
                              <div class="playlist" (click)="goToDetailPage(item2)">
                                  <img defaultImage="../../../assets/Images/musicdummy.jpg"  width="100%" [lazyLoad]="item2.small_url" />
                                  
                              </div>
                              <h3 style="color:#fff;text-align: center;">{{item2.title}}
                              </h3>
                          </div>
                      </ng-container>
                  </ngx-slick-carousel>
              </ng-container>
              </ng-container>
          </div>
      </div>
  </div>
</div>