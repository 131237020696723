<div class="emovie">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
    <div class="mobilebanner">
        <img src="{{banner}}" />
        <!-- <img src="../../../../assets/Banner - Eros promo.jpg" /> -->
    </div>
    <app-back></app-back>
    <div class="cont">

        <div class="row">
            <div class="carousel" *ngFor="let item of wholeData">
                <ng-container *ngIf="item.data.length >= length">

                    <h4 style="color: #fff">{{item.category}}</h4>
                    <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"
                        style="display:flex" class="slide">
                        <ng-container>
                            <div ngxSlickItem *ngFor="let item2 of item.data">
                                <div class="playlist" (click)="goToDetailPage(item2)">
                                    <img defaultImage="../../../assets/Images/musicdummy.jpg" width="100%"
                                        [lazyLoad]="item2.small_url" />

                                </div>
                                <h3 style="color:#fff;text-align: center;">{{item2.title}}
                                </h3>
                            </div>
                        </ng-container>
                    </ngx-slick-carousel>
                </ng-container>
                <ng-container *ngIf="item.data.length < length">

                    <h4 style="color: #fff">{{item.category}}</h4>
                    <div class="parent">
                        <div class="child" *ngFor="let item2 of item.data">
                            <div class="playlist" (click)="goToDetailPage(item2)">
                                <img defaultImage="../../../assets/Images/musicdummy.jpg" width="100%"
                                    [lazyLoad]="item2.small_url" />
                                <h3 style="color:#fff;text-align: center;">{{item2.title}}
                                </h3>
                            </div>
                        </div>
                    </div>

                </ng-container>
            </div>

            <!-- <div class="carousel" *ngFor="let item of wholeData">
                <ng-container *ngIf="item.data.length < 7">

                    <h4 style="color: #fff">{{item.category}}</h4>
                    <div class="parent" *ngFor="let item2 of item.data">
                        <div class="child" (click)="goToDetailPage(item2)">
                            <img defaultImage="../../../assets/Images/musicdummy.jpg" width="100%"
                                [lazyLoad]="item2.small_url" />
                            <h3 style="color:#fff;text-align: center;">{{item2.title}}
                            </h3>
                        </div>
                    </div>

                </ng-container>
            </div> -->
        </div>
    </div>
</div>