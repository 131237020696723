<div id="modal-content-wrapper">
    <div class="modalcontainer">
        <app-close (click)="closeModal()"></app-close> 
        <div class="avvatta">
          <div class="body">
            <div class="left">
                <h3>Access unlimited Avvatta with one card.</h3>
                <p>Pay for membership or a gift one to someone special.</p>
                <div class="card">
                    <img src="../../../assets/icons/Gift Card.PNG"/>
                </div>
            </div>
            <div class="right">
                <div class="button">
                    <div class="btn1">
                        <button>Buy Online</button>
                    </div>
                    <div class="btn2">
                        <button>Buy Instore</button>
                    </div>
                </div>
               <div class="cont">
                <div class="rows1">
                    <div class="col1">
                        <div class="btns">
                            <div class="btn">
                                <button>Online Partner</button>
                            </div>
                            <div class="btn">
                                <button>Online Partner</button>
                            </div>
                        </div>                        
                    <h4>HOW  DOES IT WORK'S?</h4>
                    </div>
                    <div class="col2">
                            <div class="image">
                                <img src="../../../assets/icons/its easy.PNG"/>
                            </div>
                            <div class="text">
                                <h4>It's easy</h4>
                                <p>Add them to an existing account or start a new one. Use them for yourself or anyone who could use a little fun.</p>
                            </div>
                    </div>
                </div>
                <div class="rows2">
                    <div class="col1">
                        <div class="image">
                            <img src="../../../assets/icons/Works like cash.PNG"/>
                        </div>
                        <div class="text">
                            <h4>Works like cash</h4>
                                <p>Cards are loaded onto your account as a gift balance. You will be notified once the balance has been depleted.</p>
                        </div>
                    </div>
                    <div class="col2">
                            <div class="image">
                                <img src="../../../assets/icons/Need more help.PNG"/>
                            </div>
                            <div class="text">
                                <h4>Need more help</h4>
                                <p>Having trouble using your gift card? Visit the Help Center for additional information and assistance.</p>
                            </div>
                    </div>
                </div>
               </div>
                <div class="foot">
                    <div class="image">
                        <img src="../../../assets/icons/Redeem Gift.PNG"/>
                    </div>
                    <div class="text">
                        <h4>Redeem your Gift Card</h4>
                    </div>
                    <div class="button">
                        <button>I'M READY</button>
                    </div>

                </div>
            </div>
          </div>
          <div class="footer">
            <app-foot (click)="closeModal()"></app-foot>
          </div>
        </div>



    </div>
</div>