<div class="kids">
  <ngx-spinner
        bdColor="rgba(51,51,51,0.8)"
        size="medium"
        color="#fff"
        type="ball-scale-multiple"
      >
        <p style="font-size: 20px; color: white">Loading...</p>
      </ngx-spinner>
    <!-- <div *ngFor="let data of cat">
      <div class="maincontainer" *ngIf="data.id == selectelearn" style="background: url('{{data.img}}');background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        position: relative;
        background-color: #000;">
        <div class="middle">
          <div class="content">
            <div class="left"></div>
            <div class="empty">
  
            </div>
            <div class="info">
            </div>
            <div class="right"></div>
          </div>
        </div> -->
        <!-- <div class="modules elearningbtn web">
          <div class="cont">
            <div class="row">
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="mainList">
                    <div ngxSlickItem *ngFor="let data of subCategoryList" class="slide">
                        <div class="btn"  (click)="goToVideoPage(data.id)">
                          <div class="style">
                            <div class="text">
                              <h3>{{data.name}}</h3>
                            </div>
                          </div>
                        </div>
                    </div>
                </ngx-slick-carousel>
            </div>
        </div>
          </div> -->
      <!-- </div>
    </div> -->

    <div class="mobilebanner" *ngFor="let data of cat">
      <img src='{{data.img}}' *ngIf="data.id == selectelearn" />
    </div>

    <div class="modules elearningbtn">
      <div class="cont">
          <div class="row">
              <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="mainList">
                  <div ngxSlickItem *ngFor="let data of subCategoryList" class="slide">
                      <div class="btn" (click)="goToVideoPage(data.id)">
                        <div class="style">
                          <div class="text">
                            <h3>{{data.name}}</h3>
                          </div>
                        </div>
                      </div>
                  </div>
              </ngx-slick-carousel>
          </div>
      </div>
  </div>
  
  <app-back></app-back>
  <div class="cont">
    <div class="row" *ngFor="let category of videoData">
    <ng-container *ngIf="category.source.length >= 7">
      <h4>{{category.sub_category_name | uppercase}}</h4>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="trending">
          <div ngxSlickItem *ngFor="let image of category.source" class="slide">
              <div class="playlist" (click)="goToPlayVideoPage(image, category.sub_category_name)">
                  <!-- <img src="{{ image.profileImage}}" width="100%"> -->
                  <img defaultImage="../../../assets/Images/vdodummy.jpeg"  width="100%" [lazyLoad]="image.profileImage" />
              </div>
              <p class="textcenter">{{image.content_name}}</p>
          </div>
      </ngx-slick-carousel>
    </ng-container>
    </div>
</div>
  </div>