    <div class="section_one">

        
       <div class="content">
       <div class="image" >
        <div class="title">
            <h4>Music > Albums > Coz I'm Happy</h4>
        </div>
           <img src="https://images004-a.erosnow.com/movie/3/1063603/img145210/6984166/1063603_6984166.jpg" alt="" width="250" height="250" class="responsive">
       </div>
            <div class="image1" >
        <div class="title1">
            <p class="p">Coz I'm Happy</p><br>
            <p>Various</p><br>
            <p>2016 . Language: Hindi</p><br>
            <span class="fa-stack fa-1x">
               
                <i class="fa fa-heart fa-stack-1x" style="border: 2px solid;
                border-radius: 50%; width: 30px;
    height: 30px;
    line-height: 30px;"></i>
              </span>
              <span class="fa-stack fa-1x">
               
                <i class="fa fa-ellipsis-h fa-stack-1x" style="border: 2px solid;
                border-radius: 50%; margin-left: 44px; width: 30px;
    height: 30px;
    line-height: 30px;"></i>
              </span>
              <span class="fa-stack fa-1x" >
               
                <i class="fa fa-facebook fa-stack-1x" style="  border-radius: 50%; margin-left: 84px; background-color: gray; width: 35px;
                height: 35px;
                line-height: 35px;" ></i>
              </span>
              <span class="fa-stack fa-1x">
               
                <i class="fa fa-twitter
                -h fa-stack-1x" style=" border-radius: 50%;  margin-left: 124px; background-color: gray; width: 35px;
                height: 35px;
                line-height: 35px;"></i>
              </span>
           
           
            
            
        </div>
       
    </div>
       </div>
        
    </div> 
    <div class="section_two">

        
        <div class="content2" style="display: flex;">
            <div class="image" >
                <div class="title">
                    <h5 style="color: gray; text-align: center;">Enjoyed the music? <br> You can also watch the movie on Eros Now.</h5>
                </div>
                   <img src="https://images004-a.erosnow.com/movie/3/1063603/img145210/6984166/1063603_6984166.jpg" alt="" width="200" height="200" class="responsive">
                   <div class="movie-title">Manmarziyaan</div> 
                   <div class="movie-detail"><span class="year">2018</span><span class="duration">&nbsp;| 155 min</span></div> 
                 <button class="button"> Book Now</button>
                </div>
            
            <table style="margin-top: 20px; margin: 5%;">
                <tr>
                <th></th>
                  <th>Track</th>
                  <th>Artist</th>
                  <!-- <th><i class="fa fa-clock-o" aria-hidden="true"></i></i></th> -->
                  <th>Watch</th>
                </tr>
              
                <tr style="margin-top: 20px;">
                    <td class="change-icon">1</td>
                  <td>Alfreds Futterkiste <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td style="color: gray;">Maria Anders</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>2</td>
                  <td>Centro comercial Moctezuma <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Francisco Chang</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>3</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>4</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>5</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>2</td>
                  <td>Centro comercial Moctezuma <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Francisco Chang</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>3</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>4</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>5</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>2</td>
                  <td>Centro comercial Moctezuma <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Francisco Chang</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>3</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>4</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                <tr>
                    <td>5</td>
                  <td>Ernst Handel <br><small  style="color: gray;">Coz I,m Happy</small></td>
                  <td  style="color: gray;">Roland Mendel</td>
                  <td>04:41</td>
                  <td><i class="fa fa-ellipsis-h"></i></td>
                </tr>
                

               
              
              </table>
        </div>
        <audio controls style="background: #f7efef;width: 100%;">
            <source src="horse.ogg" type="audio/ogg">
            <source src="horse.mp3" type="audio/mpeg">
          </audio>
       
         
     </div> 