<div class="movie">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>  
    <app-alert [Message]="alertMessage" *ngIf="alertShow" (click)="alertClose(alertMessage)"></app-alert>  
    <ng-container *ngFor="let data of descData">
        <div class="maincontainer" style="background-image: url('{{data.big_url}}');">
            <div class="middle web">
                <div class="content">
                   <div class="inside">
                    <div class="first">
                        <!-- <div class="left">&#10094;</div> -->
                    <div class="info">
                        <h1>{{data.title}}</h1>
                        <p>
                            {{data.short_description}}
                        </p>
                        <strong>
                            <p *ngIf="data.people.Actor?.length">
                                Starring: <span  *ngFor="let d of data.people.Actor"> {{d[2]}}</span>
                            </p>
                            <p *ngIf="data.people.Director?.length">
                                Creators: <span  *ngFor="let d of data.people.Director"> {{d[2]}}</span>
                            </p>
                            <p>{{data.release_year}}</p>
                        </strong>
                    </div>
                    <div class="empty">
                    </div>
                   
                    <!-- <div class="right">&#10095;</div> -->
                    </div>
                    <div class="second">
                        <div class="btncontainer">
                            <div class="left">
                                
                                <div class="buttons">
                                    <div class="play">
                                        <button (click)="playVideo(data)">PLAY NOW</button>
                                    </div>
                                    <!-- <div class="watch">
                                        <button>Play Trailer</button>
                                    </div> -->
                                    <div class="list" 
                                    (click)="share(facebook)">
                                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                                    </div>
                                    <div class="list">
                                        <i (click)="watchLaterClick()" class="fa fa-plus" aria-hidden="true"></i>
                                    </div>
                                    <div class="list">
                                        <i [ngClass]="{'active': data.iswishlist == 1}" class="fa fa-heart" aria-hidden="true" (click)="addFavourite()"></i>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="right">
                                <div>
                                    Seasons
                                </div> 
                                <div>
                                    <div>1</div>
                                </div>
                            </div> -->
                        </div>
                    </div>
                   </div>
                </div>
                
            </div>
        </div>
        <div class="mobilebanner">
            <img src="{{data.big_url}}" />
        </div>

        <div class="middle mob" *ngFor="let data of descData">
            <div class="content">
               <div class="inside">
                <div class="first">
                    <!-- <div class="left">&#10094;</div> -->
                <div class="info">
                   
                </div>
                <div class="empty">
                    <h1>{{data.title}}</h1>
                    <p>
                        {{data.short_description}}
                    </p>
                    <strong>
                        <p *ngIf="data.people.Actor?.length">
                            Starring: <span  *ngFor="let d of data.people.Actor"> {{d[2]}}</span>
                        </p>
                        <p *ngIf="data.people.Director?.length">
                            Creators: <span  *ngFor="let d of data.people.Director"> {{d[2]}}</span>
                        </p>
                        <p>{{data.release_year}}</p>
                    </strong>
                </div>
               
                <!-- <div class="right">&#10095;</div> -->
                </div>
                <div class="second">
                    <div class="btncontainer">
                        <div class="left">
                            
                            <div class="buttons">
                                <div class="play">
                                    <button (click)="playVideo(data)">PLAY NOW</button>
                                </div>
                                <!-- <div class="watch">
                                    <button>Play Trailer</button>
                                </div> -->
                                <div class="list">
                                    <i (click)="watchLaterClick()" class="fas fa-plus" aria-hidden="true"></i>
                                </div>
                                <div class="list">
                                    <i  (click)="share(facebook)" class="fa fa-share-alt" aria-hidden="true"></i>
                                </div>
                                <div class="list">
                                    <i [ngClass]="{'active': data.iswishlist != 0}" class="fa fa-heart" aria-hidden="true" (click)="addFavourite()"></i>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="right">
                            <div>
                                Seasons
                            </div> 
                            <div>
                                <div>1</div>
                            </div>
                        </div> -->
                    </div>
                </div>
               </div>
            </div>
            
        </div>
    <app-back></app-back>
    <div class="cont">
        <div class="row">
          <h4 *ngIf="data.recom?.length">People watching</h4>
          <!-- <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig"> -->
            <div class="slide">
              <div *ngFor="let recomonded of data.recom" class="playlist" (click)="selectMovie(recomonded)">
                <img defaultImage="../../../assets/Images/vdodummy.jpeg"  width="100%" [lazyLoad]="recomonded.small_url" />
                <p>{{recomonded.title}}</p>
              </div>
            </div>
          <!-- </ngx-slick-carousel> -->
        </div>
    </div>
    
</ng-container>
</div>