<div class="se">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>    
  <div class="selectelrn">
    <div *ngFor="let data of cat">
      <!-- <div class="maincontainer" *ngIf="data.id == selectelearn" style="background: url('{{data.img}}');background-position: center; 
        background-repeat: no-repeat; 
        background-size: cover;
        position: relative;
        background-color: #000;">
        <div class="middle">
          <div class="content">
            <div class="left"></div>
            <div class="empty">
  
            </div>
            <div class="info">
            </div>
            <div class="right"></div>
          </div>
        </div>
      </div> -->
      <div class="mobilebanner" *ngIf="data.id == selectelearn">
        {{data.id == selectelearn}}
        <img src="{{data.img}}" />
      </div>
    </div>
    <div class="modules">
      <div class="inner">
        <ng-container>
          <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="mainList">
            <div ngxSlickItem *ngFor="let data of list" class="slide">
              <div class="btn" (click)="vdoModals(data)">
                <div class="style">
                  <div class="text">
                    <h3>{{data.name}}</h3>
                  </div>
                </div>
              </div>
            </div>
          </ngx-slick-carousel>
        
        </ng-container>
      </div>
    </div>
      <app-back></app-back>
  </div>


  <div class="cont">
    <div class="row" *ngFor="let category of videoData">
    <ng-container *ngIf="category.source.length >= 7">
      <h4>{{category.sub_category_name | uppercase}}</h4>
      <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="trending">
          <div ngxSlickItem *ngFor="let image of category.source" class="slide">
              <div class="playlist" (click)="goToPlayVideoPage(image, category.sub_category_name)">
                  <img defaultImage="../../../assets/Images/vdodummy.jpeg"  width="100%" [lazyLoad]="image.profileImage" />
              </div>
              <p class="textcenter">{{image.content_name}}</p>
          </div>
      </ngx-slick-carousel>
    </ng-container>
    </div>
</div>
</div>