<div class="emovie">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>    
    <!-- <div class="maincontainer" style="background: url('{{banner}}');">
        <div class="middle">
            <div class="content">
                <div class="inside">
                    <div class="first">
                        <div class="info">
                        </div>
                        <div class="empty">
                        </div>
                    </div>
                    <div class="second">
                    </div>
                </div>
            </div>

        </div>
    </div> -->
    <div class="mobilebanner">
        <img src="{{banner}}" />
        <!-- <img src="../../../../assets/Banner - Eros promo.jpg" /> -->
    </div>
    <div class="modules web">
        <div class="cont">
          <div class="row">
            <div class="left">
              <div class="group">
                <div class="btn" routerLink="/vod/eros/movies">
                  <div class="style">
                    <div class="inside"  >
                      <div class="text"> Movies</div>
                    </div>
                  </div>
                </div>
                <div class="btn" routerLink="/vod/eros/series" >
                  <div class="style">
                    <div class="inside">
                      <div class="text"> Series</div>
                    </div>
                  </div>
                </div>
                <div class="btn" routerLink="/vod/eros/music">
                  <div class="style">
                    <div class="inside">
                      <!-- <div class="text" (click)="goToNextTab()"> MUSIC</div> -->
                      <div class="text" > MUSIC</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    <app-back></app-back>
    <div class="cont">

      <div class="row">
        <div class="carousel" *ngFor="let item of wholeData">
            <ng-container *ngFor="let item1 of item; let i=index"> 
                <ng-container *ngIf="item1.sources.length >= 7">

                <h4 style="color: #fff">{{item1.categories}}</h4>
                <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="slideConfig" style="display:flex" class="slide">
                    <ng-container>
                        <div ngxSlickItem *ngFor="let item2 of item1.sources">
                            <div class="playlist" (click)="goToDetailPage(item2)">
                                <img defaultImage="../../../assets/Images/vdodummy.jpeg"  width="100%" [lazyLoad]="item2.small_url" />
                                <!-- <img src="{{item2.small_url}}" width="100%"> -->
                            </div>
                            <h3 style="color:#fff;text-align: center;">{{item2.title}}
                            </h3>
                        </div>
                    </ng-container>
                </ngx-slick-carousel>
            </ng-container>
            </ng-container>
        </div>
    </div>
    </div>
</div>