<div id="modal-content-wrapper">
    <div class="modalcontainer">
        <app-close (click)="closeModal()"></app-close> 
        <div class="head">
            <h3>Recent Device Streaming Activity</h3>
            <p>Below is a list of all the devices your account has been logged into:</p>
        </div>
        <div class="body">
            <div class="list">
                <div class="heading">
                    <h3>WEB BROWSER</h3>
                </div>
                <div class="container" *ngFor="let data of streamingData">
                    <p>{{data.device_browser}}&nbsp;{{data.device_details}}&nbsp;{{data.device_os}}</p>
                </div>
            </div>
        </div>
        <div class="footer">
            <app-foot (click)="closeModal()"></app-foot>
        </div>
    </div>


</div>