<div class="accountcont">
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <div id="modal-content-wrapper">
    <div class="modalcontainer">


      <div class="account">
        <div style="background : #000">
          <app-close [routerLink]="['/']"></app-close>
        </div>
        <h4>Account</h4>
        <div class="box">
          <div class="head">
            <h3>MEMBERSHIP & BILLING</h3>
          </div>
          <div class="body">
            <div class="parent">
              <div class="child c1">
                <button (click)="cancelMemberShipModal()">Cancel Membership</button>
                <div class="mob">
                  <p *ngIf="email != ''">{{email}}</p>
                  <p>********</p>
                  <p *ngIf="phone != ''">{{countryCode}} &nbsp;{{phone}}</p>
                </div>
              </div>
              <div class="child desk">
                <p *ngIf="email != ''">{{email}}</p>
                <p>{{billingEmail}}</p>
                <p>********</p>
                <p *ngIf="phone != ''">{{countryCode}} &nbsp;{{phone}}</p>
              </div>
              <div class="child special">
                <p (click)="changeEmailModal()" *ngIf="email != ''">Change email</p>
                <p (click)="addBillingEmail()">Add Billing Email</p>
                <p (click)="changePasswordModal()">Change password</p>
                <p (click)="changePhoneModal()" *ngIf="phone != ''">Change phone number</p>
              </div>
            </div>
            <hr>
            <div class="parent">
              <div class="child">
                <p>Custom Info</p>
              </div>
              <div class="child">
                <!-- <p>Next billing date</p>
                <div class="childone">
                  <div class="image">
                    <img src="../../assets/Images/Visa Icon.PNG" />
                  </div>
                  <div class="text">
                    <p>text to be shown</p>
                  </div>
                </div> -->
              </div>
              <div class="child special">
                <p (click)="recentModal()">Recent device streaming activity</p>
                <p (click)="requestInfoModal()">Download your personal information</p>
                <!-- <p (click)="updatePayInfoModal()">Update payment information</p> -->
                <p (click)="billingModal()">Billing details</p>
                <!-- <p (click)="newBillingDay()">Change billing date</p> -->
              </div>
            </div>
            <hr>
            <div class="parent">
              <div class="child">
                <p>UnSubscribe</p>
              </div>
              <div class="child">

              </div>
              <div class="child special">

                <p (click)="changeBillModal()">Manage subscription</p>
                <p (click)="signoutModal()">Signout of all device</p>
                <!-- <p (click)="avvattaCard()">Where to buy giftcards</p> -->
                <!-- <p (click)="giftModal()">Redeem gift card or promo code</p> -->
              </div>
            </div>
            <!-- <hr>
                <div class="parent">
                    <div class="child1 special">
                        <p (click)="recentModal()">Recent device streaming activity</p>
                        <p (click)="requestInfoModal()">Download your personal information</p>
                        <p (click)="changeBillModal()">Manage subscription</p>
                    </div>
                  <div class="child special">
                        <p (click)="downloadModal()">Manage download device</p>
                        <p (click)="signoutModal()">Signout of all device</p>
                  </div>
                </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>