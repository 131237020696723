<div id="modal-content-wrapper">
    <div class="modalcontainer" >
        <div>
            <div class="close" (click)="closeModal()">
                <i class="fa fa-close" aria-hidden="true"></i>
            </div>
           <div class="player">
            <div style="width: 100%; margin: auto;">
                <div class='video' (click)="check()">


                    <div id="myElement"></div>
                </div>
            </div>
           </div>
        </div>
    </div>
</div>



<!-- <div class='video'>


    <div id="myElement"></div>
</div> -->