<div class="vod">

  <app-alert [Message]="alertMessage" *ngIf="alertShow" (click)="alertClose(alertMessage)"></app-alert>
  <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Loading...</p>
  </ngx-spinner>
  <!-- <div class="maincontainer" style="background: url('{{banner}}');">
    <div class="middle">
      <div class="content">
        <div class="left">&#10094;</div>
        <div class="empty">

        </div>
        <div class="info">
        </div>
        <div class="right">&#10095;</div>
      </div>
    </div>

  </div> -->
  <div class="mobilebanner">
    <img src="{{banner}}" />
  </div>
  <div class="modules web">
    <div class="cont">
      <div class="row">
        <div class="left">
          <div class="button">
            <span></span>
            <div class="vodtext"><img src="../../assets/Images/Avvatta VOD Text.PNG" /> </div>
            <span></span>
          </div>
          <div class="group">
            <div class="btn" (click)="goToNextTab()">
              <div class="style">
                <div class="inside">
                  <div class="text"> Movies</div>
                </div>
              </div>
            </div>
            <div class="btn" (click)="goToNextTab()">
              <div class="style">
                <div class="inside">
                  <div class="text"> Series</div>
                </div>
              </div>
            </div>
            <div class="btn" (click)="goToNextTab()">
              <div class="style">
                <div class="inside">
                  <div class="text"> SHOWS</div>
                </div>
              </div>
            </div>
          </div>
          <div class="border">

          </div>
        </div>
        <div class="right">
          <div class="button">
            <span></span>
            <div class="vodtext"><img src="../../assets/Images/Avvatta VOD Text.PNG" /> </div>
            <span></span>
          </div>
          <div>
            <div class="btn"   [routerLink]='["/vod/flimdo"]'>
              <div class="style">
                <div class="inside">
                  <div class="text"> FilmDoo rental</div>
                </div>
              </div>
            </div>
            <!-- <div class="btn" (click)="goToNextTab()">
              <div class="style">
                <div class="inside">
                  <div class="text"> eros now</div>
                </div>
              </div>
            </div> -->
            <div class="btn" [routerLink]='["/vod/eros"]'>
              <div class="style">
                <div class="inside">
                  <div class="text"> eros now</div>
                </div>
              </div>
            </div>
          </div>
          <div class="border">

          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="modules mobile">
    <div class="cont">
      <div class="row">
        <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="btn">
          <div ngxSlickItem class="slide">
            <div class="left">
              <div class="button">
                <span></span>
                <div class="vodtext"><img src="../../assets/Images/Avvatta VOD Text.PNG" /> </div>
                <span></span>
              </div>
              <div class="group">
                <div class="btn" (click)="goToNextTab()">
                  <div class="style">
                    <div class="inside">
                      <div class="text"> Movies</div>
                    </div>
                  </div>
                </div>
                <div class="btn" (click)="goToNextTab()">
                  <div class="style">
                    <div class="inside">
                      <div class="text"> Series</div>
                    </div>
                  </div>
                </div>
                <div class="btn" (click)="goToNextTab()">
                  <div class="style">
                    <div class="inside">
                      <div class="text"> SHOWS</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border">

              </div>
            </div>
          </div>
          <div ngxSlickItem class="slide">
            <div class="right">
              <div class="button">
                <span></span>
                <div class="vodtext"><img src="../../assets/Images/Avvatta VOD Text.PNG" /> </div>
                <span></span>
              </div>
              <div>
                <div class="btn" (click)="goToNextTab()">
                  <div class="style">
                    <div class="inside">
                      <div class="text"> FilmDoo rental</div>
                    </div>
                  </div>
                </div>
                <!-- <div class="btn" (click)="goToNextTab()">
                  <div class="style">
                    <div class="inside">
                      <div class="text"> eros now</div>
                    </div>
                  </div>
                </div> -->
                <div class="btn" [routerLink]='["/vod/eros"]'>
                  <div class="style">
                    <div class="inside">
                      <div class="text"> eros now</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="border">

              </div>
            </div>
          </div>
        </ngx-slick-carousel>
      </div>
    </div>
  </div>
  <app-back></app-back>

  <div class="cont">

    <div class="row">
      <div class="carousel" *ngFor="let item of wholeData">
        <ng-container *ngFor="let item1 of item; let i=index">
          <ng-container *ngIf="item1.sources.length >= 7">

            <h4 style="color: #fff">{{item1.categories}}</h4>
            <ngx-slick-carousel class="carousel" #slickModal="slick-carousel" [config]="trending" style="display:flex"
              class="slide">
              <ng-container>
                <div ngxSlickItem *ngFor="let item2 of item1.sources">
                  <div class="playlist" (click)="goToDetailPage(item2)">
                    <img defaultImage="../../../assets/Images/vdodummy.jpeg" width="100%"
                      [lazyLoad]="item2.small_url" />
                  </div>
                  <h3 style="color:#fff;text-align: center;">{{item2.title}}
                  </h3>
                </div>
              </ng-container>
            </ngx-slick-carousel>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>
