<head>
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-MMXXTCF');</script>
    <!-- End Google Tag Manager -->
</head>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MMXXTCF"
    height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
<div class="entertainment">
    <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
        <p style="font-size: 20px; color: white">Loading...</p>
    </ngx-spinner>
    <div class="mobilebanner">
        <img src="{{banner}}" />
    </div>
    <!-- <div id="local" class="maincontainer free" style="background: url('{{banner}}');">
        <div class="middle">
            <div class="content">
                <div class="left">
                    &#10094;
                </div>
                <div class="empty">

                </div>
                <div class="info">
                </div>
                <div class="right">
                    &#10095;
                </div>
            </div>
        </div>
    </div> -->
    <app-back></app-back>
    <!-- <div id="local" class="back">
        <div id="local">
            <a id="local" href="{{previousUrl}}">
                <i id="local" class="fa fa-arrow-left" aria-hidden="true"></i>
            </a>
        </div>
    </div> -->
    <div class="cont">
        <div class="row">
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Avvatta Challenges</h3>
              <div>
                <script async src="assets/js/embed-feed.js" type="text/javascript" ></script>
                  <fw-embed-feed
                  channel="avvatta"
                  playlist="gKzXko"
                  mode="row"
                  open_in="default"
                  max_videos="8"
                  placement="middle"
                  player_placement="bottom-right"
                  branding="false"
                  size="medium">
                </fw-embed-feed>
              </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Africa</h3>
              <div>
                <script async src="assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="Nv7brg"
                mode="row"
                open_in="default"
                max_videos="8"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
              <!-- <div style="display:flex;flex-wrap:nowrap;">
                <div style="width:calc(100% - 300px);margin-right:25px;">
                 
                </div>
                <div style="width: 300px;">
                  <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
                  <script>
                    window.googletag = window.googletag || { cmd: [] };
                    googletag.cmd.push(function () {
                      googletag.defineSlot('/21796380238,22327168071/op1vlwQNnznCgfWK13vc1fcNDfJkmm2j', [300, 250], '300x250').addService(googletag
                        .pubads());
                      googletag.pubads().enableSingleRequest();
                      googletag.enableServices();
                    });
                  </script>
                  <div id='300x250' style='width: 300px; height: 250px;'>
                    <script>
                      googletag.cmd.push(function () {
                        googletag.display('300x250');
                      });
                    </script>
                  </div>
                </div> -->
               
              <!-- <div id="africa"></div> -->
      
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Beauty and Fashion</h3>
              <!-- <div id="beauty"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="Po999o"
                mode="row"
                open_in="default"
                max_videos="8"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Cars and Automotive</h3>
              
              <!-- <div style="display:flex;flex-wrap:nowrap;">
                <div style="width:calc(100% - 300px);margin-right:25px;">
                 
                </div>
                <div style="width: 300px;">
                  <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
                  <script>
                    window.googletag = window.googletag || { cmd: [] };
                    googletag.cmd.push(function () {
                      googletag.defineSlot('/21796380238,22327168071/YcxPwZxDWv7f-fxR9GH_ga2fj2NQew9P', [300, 250], '300x250').addService(googletag
                        .pubads());
                      googletag.pubads().enableSingleRequest();
                      googletag.enableServices();
                    });
                  </script>
                  <div id='300x250' style='width: 300px; height: 250px;'>
                    <script>
                      googletag.cmd.push(function () {
                        googletag.display('300x250');
                      });
                    </script>
                  </div>
                </div> -->
               
              <!-- <div id="cars"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="xvJBWv"
                mode="row"
                open_in="default"
                max_videos="8"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Comedy</h3>
              <!-- <div id="comedy"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="MgdPJg"
                mode="row"
                open_in="default"
                max_videos="8"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Animals and Pets</h3>
              <!-- <div style="display:flex;flex-wrap:nowrap;">
                <div style="width:calc(100% - 300px);margin-right:25px;">
                  <fw-embed-feed
                    channel="avvatta" 
                    playlist="Zg2Jlg"
                    mode="row"
                    open_in="_iframe"
                    max_videos="30"
                    placement="middle">
                  </fw-embed-feed>
                </div>
                <div style="width: 300px;">
                  <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
                  <script>
                    window.googletag = window.googletag || { cmd: [] };
                    googletag.cmd.push(function () {
                      googletag.defineSlot('/21796380238,22327168071/ztTk8ePBZzau68p5wpvSp-pYltIXofpO', [300, 250], '300x250').addService(googletag
                        .pubads());
                      googletag.pubads().enableSingleRequest();
                      googletag.enableServices();
                    });
                  </script>
                  <div id='300x250' style='width: 300px; height: 250px;'>
                    <script>
                      googletag.cmd.push(function () {
                        googletag.display('300x250');
                      });
                    </script>
                  </div>
                </div> -->
               
              <!-- <div id="animal"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="Zg2Jlg"
                mode="row"
                open_in="default"
                max_videos="8"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Entertainment</h3>
              <!-- <div id="entertainment"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="KgO2Bo"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Environment</h3>
              <!-- <div style="display:flex;flex-wrap:nowrap;">
                <div style="width:calc(100% - 300px);margin-right:25px;">
                  <fw-embed-feed
                    channel="avvatta" 
                    playlist="KgONdo"
                    mode="row"
                    open_in="_iframe"
                    max_videos="30"
                    placement="middle">
                  </fw-embed-feed>
                </div>
                <div style="width: 300px;">
                  <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
                  <script>
                    window.googletag = window.googletag || { cmd: [] };
                    googletag.cmd.push(function () {
                      googletag.defineSlot('/21796380238,22327168071/3_M7IQjPpTPuhidjIotaL-shJ6hsh1ZR', [300, 250], '300x250').addService(googletag
                        .pubads());
                      googletag.pubads().enableSingleRequest();
                      googletag.enableServices();
                    });
                  </script>
                  <div id='300x250' style='width: 300px; height: 250px;'>
                    <script>
                      googletag.cmd.push(function () {
                        googletag.display('300x250');
                      });
                    </script>
                  </div>
                </div> -->
               
              <!-- <div id="environment"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="KgONdo"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Food</h3>
              <!-- <div id="food"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="jgKLJv"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Gaming</h3>
              <!-- <div style="display:flex;flex-wrap:nowrap;">
                <div style="width:calc(100% - 300px);margin-right:25px;">
                  <fw-embed-feed
                    channel="avvatta" 
                    playlist="JvrYVg"
                    mode="row"
                    open_in="_iframe"
                    max_videos="30"
                    placement="middle">
                  </fw-embed-feed>
                </div>
                <div style="width: 300px;">
                  <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
                  <script>
                    window.googletag = window.googletag || { cmd: [] };
                    googletag.cmd.push(function () {
                      googletag.defineSlot('/21796380238,22327168071/oGqakf69ki18YQv6iJcFQuJEDrAGYFET', [300, 250], '300x250').addService(googletag
                        .pubads());
                      googletag.pubads().enableSingleRequest();
                      googletag.enableServices();
                    });
                  </script>
                  <div id='300x250' style='width: 300px; height: 250px;'>
                    <script>
                      googletag.cmd.push(function () {
                        googletag.display('300x250');
                      });
                    </script>
                  </div>
                </div> -->
               
              <!-- <div id="game"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="JvrYVg"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Sport</h3>
              <!-- <div id="sport"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="joWQmv"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Technology</h3>
              <!-- <div id="tech"></div> -->
              <div>
                <script async src="../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="W5Edrv"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Travel</h3>
              <!-- <div id="travel"></div> -->
              <div>
                <script async src="../../.../../../assets/js/embed-feed.js" type="text/javascript" ></script>
                <fw-embed-feed
                channel="avvatta"
                playlist="7vZk45"
                mode="row"
                open_in="default"
                max_videos="0"
                placement="middle"
                player_placement="bottom-right"
                branding="false"
                size="medium">
              </fw-embed-feed>
            </div>
            </div>
            <!-- <div style="background:#000;">
              <h3 style="margin:0; padding:10px; color:#fff">Gadgets</h3> -->
              <!-- <div style="display:flex;flex-wrap:nowrap;">
                <div style="width:calc(100% - 300px);margin-right:25px;">
                  <fw-embed-feed
                    channel="avvatta" 
                    playlist="W5Edrv"
                    mode="row"
                    open_in="_iframe"
                    max_videos="30"
                    placement="middle">
                  </fw-embed-feed>
                </div>
                <div style="width: 300px;">
                  <script async src="https://securepubads.g.doubleclick.net/tag/js/gpt.js"></script>
                  <script>
                    window.googletag = window.googletag || { cmd: [] };
                    googletag.cmd.push(function () {
                      googletag.defineSlot('/21796380238,22327168071/8gOtDuo-xncTw1GEHoX-8YXRax-PKl-L', [300, 250], '300x250').addService(googletag
                        .pubads());
                      googletag.pubads().enableSingleRequest();
                      googletag.enableServices();
                    });
                  </script>
                  <div id='300x250' style='width: 300px; height: 250px;'>
                    <script>
                      googletag.cmd.push(function () {
                        googletag.display('300x250');
                      });
                    </script>
                  </div>
                </div> -->
               
              <!-- <div id="travel"></div>
      
            </div> -->
          </div>
        </div>
    </div>

</div>