<div id="modal-content-wrapper">
    <div class="modalcontainer">

        <ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
            <p style="font-size: 20px; color: white">Loading...</p>
        </ngx-spinner>
        <app-alert [Message]="alertMessage" *ngIf="alertShow" (click)="alertClose(alertMessage)"></app-alert>
        <!-- Enter password Start -->
        <div class="epassword" [ngStyle]="{ 'display': passwordShow }">
            <div class="header" (click)="closeModal()">
                <i class="fa fa-close"></i>
            </div>
            <div class="passcontainer">
                <h3>Enter Password</h3>
                <p>To setup your parental controls - please enter your password to proceed</p>
                <p>(So that we know its actually you)</p>
                <form #password="ngForm" (ngSubmit)="enterPassword(password)">
                    <div class="input">
                        <input type="password" name="password" placeholder="Enter password" ngModel required>
                    </div>
                    <p class="min">Minimum 8 charcters</p>
                    <div class="button">
                        <button type="submit">CONTINUE</button>
                        <button type="button" (click)="forgotPasswordShows()">FORGOT PASSWORD</button>
                    </div>
                </form>
            </div>
            <div class="footer">
                <app-foot (click)="closeModal()"></app-foot>
            </div>
        </div>
        <!-- Enter password End -->
        <!-- Parental Control Start -->
        <div class="parental" [ngStyle]="{ 'display': parentalControlShow }">
            <div class="edit">
                <div class="header">
                    <div class="head">
                        <h3>Parental Control</h3>
                    </div>
                    <div class="close" (click)="closeModal()"><i class="fa fa-close"></i></div>
                </div>

                <form #setpin="ngForm" (ngSubmit)="parentalControl(setpin)">
                    <div class="body">
                        <div class="icon">
                            <div>
                                <img src="../../assets/icons/Parental Control.PNG" />
                            </div>
                        </div>
                        <div class="profile">
                            <h3>You can create a 4 digit PIN to restrict access to the following functions.</h3>
                            <ul class="list">
                                <li>Switch Profile</li>
                                <li>Manage Profile</li>
                                <li>Account & Payment Information</li>
                            </ul>
                            <div class="name">
                                <div class="fname">
                                    <input type="password" maxlength="4" name="pin" ngModel required
                                        placeholder="Enter PIN" >
                                </div>
                                <div class="lname">
                                    <input type="password" maxlength="4" name="cpin" required ngModel placeholder="Confirm PIN"
                                        (focusout)="checkPin(setpin.value.pin, setpin.value.cpin)">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="buttoncontainer">
                        <div class="button">
                            <div class="btn1">
                                <button>SAVE</button>
                            </div>
                        </div>
                    </div>

                </form>
            </div>
            <div class="footer">
                <app-foot (click)="closeModal()"></app-foot>
            </div>
        </div>
        <!-- Parental Control End -->
        <!-- Forgot PIN Start -->
        <div class="fpin epassword" [ngStyle]="{ 'display': forgotPinShow }">
            <div class="header" (click)="closeModal()">
                <i class="fa fa-close"></i>
            </div>
            <div class="passcontainer">
                <h3>Forgot Parental PIN Code</h3>
                <p>Enter the email or mobile number used when registering for Avvatta and we will send you instructions
                    to reset your PIN</p>
                <form #fpin="ngForm" (ngSubmit)="forgotPin(fpin)">
                    <div class="input">
                        <input type="text" name="email" placeholder="Email / Mobile number" ngModel required>
                        <p class="dangeralert" [ngStyle]="{ 'display': pinAlert }">Please enter a valid email address
                        </p>
                    </div>
                    <div class="button">
                        <button>SEND</button>
                    </div>
                </form>
            </div>
            <div class="footer">
                <app-foot (click)="closeModal()"></app-foot>
            </div>
        </div>
        <!-- Forgot PIN End -->

        <!-- Forgot password Start -->
        <div class="fpin epassword" [ngStyle]="{ 'display': forgotPasswordShow }">
            <div class="header" (click)="closeModal()">
                <i class="fa fa-close"></i>
            </div>
            <app-forgotpassword></app-forgotpassword>
            <!-- <div class="passcontainer">
                <h3>Forgot Password</h3>
                <p>Enter the email or mobile number used when registering for Avvatta and we will send you instructions
                    to reset your Password</p>
                <form #forgotpass="ngForm" (ngSubmit)="forgotPassword(forgotpass)">
                    <div class="input">
                        <input type="text" name="email" placeholder="Email / Mobile number" ngModel required (keyup)="checkMail(forgotpass.value.email)">
                        <p class="dangeralert" *ngIf="emailId">Please enter a valid email address or mobile number
                        </p>
                    </div>
                    <div class="button">
                        <button>SEND</button>
                    </div>
                </form>
            </div> -->
            <div class="footer">
                <app-foot (click)="closeModal()"></app-foot>
            </div>
        </div>
        <!-- Forgot password End -->
    </div>
</div>
